@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?70028264');
  src: url('../font/fontello.eot?70028264#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?70028264') format('woff2'),
       url('../font/fontello.woff?70028264') format('woff'),
       url('../font/fontello.ttf?70028264') format('truetype'),
       url('../font/fontello.svg?70028264#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?70028264#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-crossFill:before { content: '\e801'; } /* '' */
.icon-audioOff:before { content: '\e802'; } /* '' */
.icon-arrow:before { content: '\e803'; } /* '' */
.icon-arrowFill:before { content: '\e804'; } /* '' */
.icon-audio:before { content: '\e805'; } /* '' */
.icon-audioLight:before { content: '\e806'; } /* '' */
.icon-muteFill:before { content: '\e807'; } /* '' */
.icon-muteAudio:before { content: '\e808'; } /* '' */
.icon-bookmark:before { content: '\e809'; } /* '' */
.icon-favouriteAdd:before { content: '\e80a'; } /* '' */
.icon-favouriteRemove:before { content: '\e80b'; } /* '' */
.icon-tickFill:before { content: '\e80c'; } /* '' */
.icon-check:before { content: '\e80d'; } /* '' */
.icon-screen:before { content: '\e80e'; } /* '' */
.icon-eyeFill:before { content: '\e80f'; } /* '' */
.icon-avatar:before { content: '\e810'; } /* '' */
.icon-friend:before { content: '\e811'; } /* '' */
.icon-friends2:before { content: '\e813'; } /* '' */
.icon-help:before { content: '\e815'; } /* '' */
.icon-helpFill:before { content: '\e816'; } /* '' */
.icon-leaveFill:before { content: '\e817'; } /* '' */
.icon-leave:before { content: '\e818'; } /* '' */
.icon-logout:before { content: '\e819'; } /* '' */
.icon-search:before { content: '\e81a'; } /* '' */
.icon-playFill:before { content: '\e81b'; } /* '' */
.icon-menuHorizontal:before { content: '\e81c'; } /* '' */
.icon-menu:before { content: '\e81d'; } /* '' */
.icon-micFill3:before { content: '\e81e'; } /* '' */
.icon-eye:before { content: '\e81f'; } /* '' */
.icon-micFill2:before { content: '\e820'; } /* '' */
.icon-facebook:before { content: '\e821'; } /* '' */
.icon-mic-1:before { content: '\e822'; } /* '' */
.icon-micOptions:before { content: '\e823'; } /* '' */
.icon-removeFill:before { content: '\e824'; } /* '' */
.icon-calendarTime:before { content: '\e825'; } /* '' */
.icon-sendFill:before { content: '\e826'; } /* '' */
.icon-send:before { content: '\e827'; } /* '' */
.icon-pencil:before { content: '\e828'; } /* '' */
.icon-phoneFill:before { content: '\e829'; } /* '' */
.icon-plusFill:before { content: '\e82a'; } /* '' */
.icon-add:before { content: '\e82b'; } /* '' */
.icon-chat2:before { content: '\e82c'; } /* '' */
.icon-favourite:before { content: '\e82d'; } /* '' */
.icon-trashFill:before { content: '\e82e'; } /* '' */
.icon-trash:before { content: '\e82f'; } /* '' */
.icon-userFill:before { content: '\e830'; } /* '' */
.icon-user:before { content: '\e831'; } /* '' */
.icon-twoUsersFill:before { content: '\e832'; } /* '' */
.icon-twoUsers:before { content: '\e833'; } /* '' */
.icon-threeUsersFill:before { content: '\e834'; } /* '' */
.icon-threeUsers:before { content: '\e835'; } /* '' */
.icon-video:before { content: '\e836'; } /* '' */
.icon-videoCameraFill2:before { content: '\e837'; } /* '' */
.icon-videoCamera2:before { content: '\e838'; } /* '' */
.icon-videoCameraFill:before { content: '\e839'; } /* '' */
.icon-videoCamera:before { content: '\e83a'; } /* '' */
.icon-normalTicket:before { content: '\e83b'; } /* '' */
.icon-stream2:before { content: '\e83c'; } /* '' */
.icon-premiumTicket:before { content: '\e83d'; } /* '' */
.icon-freeTicket:before { content: '\e83e'; } /* '' */
.icon-micFill:before { content: '\e854'; } /* '' */
.icon-micMuteLine:before { content: '\e855'; } /* '' */
.icon-micMute:before { content: '\e856'; } /* '' */
.icon-stream:before { content: '\e857'; } /* '' */
.icon-streamDot:before { content: '\e858'; } /* '' */
.icon-videoFill:before { content: '\e859'; } /* '' */
.icon-camOff:before { content: '\e85a'; } /* '' */
.icon-videoFillDisabledLine:before { content: '\e85c'; } /* '' */
.icon-videoFillDisabled:before { content: '\e85d'; } /* '' */
.icon-emoji:before { content: '\e85e'; } /* '' */
.icon-tickOutline:before { content: '\e85f'; } /* '' */
.icon-arrowBack:before { content: '\e860'; } /* '' */
.icon-panelArrow:before { content: '\e861'; } /* '' */
.icon-edit:before { content: '\e863'; } /* '' */
.icon-endCall:before { content: '\e864'; } /* '' */
.icon-friends:before { content: '\e865'; } /* '' */
.icon-friendsRequrest:before { content: '\e866'; } /* '' */
.icon-reconnecting:before { content: '\e868'; } /* '' */
.icon-mic:before { content: '\e869'; } /* '' */
.icon-mic2Mute:before { content: '\e86a'; } /* '' */
.icon-displayMedium:before { content: '\e86d'; } /* '' */
.icon-message:before { content: '\e86e'; } /* '' */
.icon-privateMessages:before { content: '\e873'; } /* '' */
.icon-removeUser:before { content: '\e875'; } /* '' */
.icon-screenShare:before { content: '\e877'; } /* '' */
.icon-sendMessage:before { content: '\e878'; } /* '' */
.icon-settings:before { content: '\e879'; } /* '' */
.icon-displaySmall:before { content: '\e87a'; } /* '' */
.icon-accept:before { content: '\e87c'; } /* '' */
.icon-addUser:before { content: '\e87d'; } /* '' */
.icon-audioOn:before { content: '\e880'; } /* '' */
.icon-back:before { content: '\e881'; } /* '' */
.icon-block:before { content: '\e882'; } /* '' */
.icon-cross:before { content: '\e883'; } /* '' */
.icon-blocked:before { content: '\e884'; } /* '' */
.icon-displayList:before { content: '\e885'; } /* '' */
.icon-cam:before { content: '\e886'; } /* '' */
.icon-chats:before { content: '\e887'; } /* '' */
.icon-remove:before { content: '\e888'; } /* '' */
.icon-moon:before { content: '\e889'; } /* '' */
.icon-next:before { content: '\e88a'; } /* '' */
.icon-pause:before { content: '\e88b'; } /* '' */
.icon-play:before { content: '\e88c'; } /* '' */
.icon-sun:before { content: '\e88d'; } /* '' */
.icon-audioOn1:before { content: '\e88f'; } /* '' */
.icon-eyeCrossed:before { content: '\e893'; } /* '' */
.icon-mirrorHorizontally:before { content: '\e894'; } /* '' */
.icon-casaSettings:before { content: '\e895'; } /* '' */
.icon-fullscreenExit:before { content: '\e899'; } /* '' */
.icon-fullscreenEnter:before { content: '\e89a'; } /* '' */
.icon-privateChat:before { content: '\e976'; } /* '' */
.icon-videoPlaylist:before { content: '\e977'; } /* '' */
.icon-highSignal:before { content: '\e978'; } /* '' */
.icon-mediumSignal:before { content: '\e979'; } /* '' */
.icon-lowSignal:before { content: '\e97a'; } /* '' */
.icon-noSignal:before { content: '\e97b'; } /* '' */
.icon-addToPlaylist:before { content: '\e97c'; } /* '' */
.icon-move:before { content: '\e97d'; } /* '' */
.icon-youtube:before { content: '\e97e'; } /* '' */
.icon-youtubeLive:before { content: '\e97f'; } /* '' */
.icon-zucasa:before { content: '\e980'; } /* '' */
.icon-tiktok:before { content: '\e981'; } /* '' */
.icon-chatRounded:before { content: '\f03d'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-facebook-1:before { content: '\f09a'; } /* '' */
.icon-mute:before { content: '\f131'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
