@font-face {
	font-family: 'Greycliff CF';
	src: url('../font/GreycliffCF-Thin.eot'),
	url('../font/GreycliffCF-Thin.woff2') format('woff2'),
	url('../font/GreycliffCF-Thin.woff') format('woff');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Greycliff CF';
	src: url('../font/GreycliffCF-ExtraLight.eot'),
	url('../font/GreycliffCF-ExtraLight.woff2') format('woff2'),
	url('../font/GreycliffCF-ExtraLight.woff') format('woff');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Greycliff CF';
	src: url('../font/GreycliffCF-Light.eot'),
	url('../font/GreycliffCF-Light.woff2') format('woff2'),
	url('../font/GreycliffCF-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Greycliff CF';
	src: url('../font/GreycliffCF-Regular.eot'),
	url('../font/GreycliffCF-Regular.woff2') format('woff2'),
	url('../font/GreycliffCF-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Greycliff CF';
	src: url('../font/GreycliffCF-Medium.eot'),
	url('../font/GreycliffCF-Medium.woff2') format('woff2'),
	url('../font/GreycliffCF-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Greycliff CF';
	src: url('../font/GreycliffCF-DemiBold.eot'),
	url('../font/GreycliffCF-DemiBold.woff2') format('woff2'),
	url('../font/GreycliffCF-DemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Greycliff CF';
	src: url('../font/GreycliffCF-Bold.eot'),
	url('../font/GreycliffCF-Bold.woff2') format('woff2'),
	url('../font/GreycliffCF-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Greycliff CF';
	src: url('../font/GreycliffCF-ExtraBold.eot'),
	url('../font/GreycliffCF-ExtraBold.woff2') format('woff2'),
	url('../font/GreycliffCF-ExtraBold.woff') format('woff');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Greycliff CF';
	src: url('../font/GreycliffCF-Heavy.eot'),
	url('../font/GreycliffCF-Heavy.woff2') format('woff2'),
	url('../font/GreycliffCF-Heavy.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}


