$grid-columns: 24;
$spacer: 1rem;

$spacers: (
	0: 0,
	1: ($spacer * .25),
	2: ($spacer * .5),
	3: ($spacer * .75),
	4: $spacer,
	5: ($spacer * 1.25),
	6: ($spacer * 1.5),
	8: ($spacer * 2),
	10: ($spacer * 2.5),
	12: ($spacer * 3),
	14: ($spacer * 3.5),
	16: ($spacer * 4)
);
@import "~bootstrap-4-grid/scss/grid";
